@import url("https://use.typekit.net/nth3kzr.css");
/* // Use Example
$min_width: 360px;
$max_width: 1920px;
$min_font: 18px;
$max_font: 55px;
@include fluid-type($min_width, $max_width, $min_font, $max_font); */
.show-for-medium {
  display: none;
}
@media only screen and (min-width: 768px) {
  .show-for-medium {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .hide-for-medium {
    display: none;
  }
}

.splash {
  overflow-x: hidden;
  position: relative;
  font-family: "co-headline", sans-serif;
  font-weight: 400;
  background: #F0F0F0;
  min-height: 100vh;
  display: grid;
  grid-template-rows: 0px auto 1fr 25px;
  margin: 0;
  padding: 0;
}
.splash__header img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  width: 63vw;
  min-width: 228px;
}
@media only screen and (min-width: 768px) {
  .splash__header img {
    width: 40vw;
  }
}
@media only screen and (min-width: 992px) {
  .splash__header img {
    width: 22vw;
    min-width: 250px;
    max-width: 420px;
  }
}
.splash__content {
  overflow-x: hidden;
}
.splash__content--espa {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}
@media only screen and (min-width: 768px) {
  .splash__content--espa {
    margin-bottom: 100px;
  }
}
.splash__content--espa img {
  max-width: 100%;
  height: auto;
}
.splash__grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin-top: 28px;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) {
  .splash__grid {
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    column-gap: 40px;
    margin-top: 60px;
    margin-bottom: 100px;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__grid {
    grid-template-columns: 1fr 1fr;
    column-gap: 65px;
  }
}
@media only screen and (min-width: 1400px) {
  .splash__grid {
    column-gap: 200px;
  }
}
@media only screen and (min-width: 1500px) {
  .splash__grid {
    column-gap: 290px;
  }
}
@media only screen and (min-width: 1640px) {
  .splash__grid {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 50px;
  }
}
.splash__grid--title {
  color: #362063;
  font-weight: 400;
  line-height: 1.3;
  margin-top: 0;
  margin-bottom: 17px;
  text-align: center;
}
.splash__grid--title {
  font-size: 16px;
}
@media screen and (min-width: 360px) {
  .splash__grid--title {
    font-size: calc(16px + 2 * ((100vw - 360px) / 1560));
  }
}
@media screen and (min-width: 1920px) {
  .splash__grid--title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 768px) {
  .splash__grid--title {
    margin-bottom: 14px;
  }
}
.splash__grid--left, .splash__grid--right, .splash__grid--center {
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) {
  .splash__grid--left, .splash__grid--right, .splash__grid--center {
    margin-bottom: 80px;
    width: 350px;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__grid--left, .splash__grid--right, .splash__grid--center {
    width: 500px;
  }
}
@media only screen and (min-width: 1640px) {
  .splash__grid--left, .splash__grid--right, .splash__grid--center {
    margin-bottom: 0;
    order: 1;
  }
}
.splash__grid--left a, .splash__grid--right a, .splash__grid--center a {
  text-decoration: none;
  transition: all 0.3s ease;
}
@media only screen and (min-width: 1200px) {
  .splash__grid--left a:hover .splash__box:before, .splash__grid--right a:hover .splash__box:before, .splash__grid--center a:hover .splash__box:before {
    opacity: 1;
  }
  .splash__grid--left a:hover .splash__box--link, .splash__grid--right a:hover .splash__box--link, .splash__grid--center a:hover .splash__box--link {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  }
}
@media only screen and (min-width: 768px) {
  .splash__grid--left {
    justify-self: flex-end;
  }
}
@media only screen and (min-width: 1640px) {
  .splash__grid--left {
    order: 1;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__grid--left .splash__box:before {
    height: 439px;
    background-image: url("/img/splash/desktop/invoicing-hover.svg");
    left: 54%;
  }
}
.splash__grid--left .splash__box--title {
  color: #362063;
}
.splash__grid--left .splash__box--title:after {
  background: #34A2A2;
}
.splash__grid--left .splash__box--title svg {
  height: auto;
  width: 120px;
}
@media only screen and (min-width: 768px) {
  .splash__grid--left .splash__box--title svg {
    width: 8.8vw;
    min-width: 120px;
    max-width: 167px;
  }
}
.splash__grid--left .splash__box--link {
  background: #34A2A2;
}
.splash__grid--left .splash__box--icon {
  position: absolute;
  top: 16px;
  right: 37px;
  width: 80px;
  height: auto;
  z-index: 1;
}
@media only screen and (min-width: 1640px) {
  .splash__grid--right {
    order: 3;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__grid--right .splash__box:before {
    height: 431px;
    background-image: url("/img/splash/desktop/accounting-hover.svg");
    left: 46%;
  }
}
.splash__grid--right .splash__box--title {
  color: #3EB7BA;
}
.splash__grid--right .splash__box--title:after {
  background: #362063;
}
.splash__grid--right .splash__box--title span {
  color: #FFCC00;
}
.splash__grid--right .splash__box--title img {
  height: auto;
  width: 140px;
}
@media only screen and (min-width: 768px) {
  .splash__grid--right .splash__box--title img {
    width: 10vw;
    min-width: 130px;
    max-width: 189px;
  }
}
.splash__grid--right .splash__box--link {
  background: #362063;
}
.splash__grid--right .splash__box--icon {
  position: absolute;
  top: 14px;
  right: 37px;
  width: 64px;
  height: auto;
  z-index: 1;
}
.splash__grid--center {
  margin: 0 auto 60px;
  grid-column: 1/-1;
}
@media only screen and (min-width: 768px) {
  .splash__grid--center {
    margin: 0 auto 80px;
  }
}
@media only screen and (min-width: 1640px) {
  .splash__grid--center {
    margin: 0 auto;
    grid-column: span 1/span 1;
    order: 2;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__grid--center .splash__box:before {
    height: 431px;
    background-image: url("/img/ypahes/ypaies.svg");
    left: 46%;
  }
}
.splash__grid--center .splash__box--title {
  color: #3EB7BA;
}
.splash__grid--center .splash__box--title:after {
  background: #FFCC00;
}
.splash__grid--center .splash__box--title span {
  color: #FFCC00;
}
.splash__grid--center .splash__box--title svg {
  height: auto;
  width: 120px;
}
@media only screen and (min-width: 768px) {
  .splash__grid--center .splash__box--title svg {
    width: 8.8vw;
    min-width: 120px;
    max-width: 167px;
  }
}
.splash__grid--center .splash__box--link {
  background: #FFCC00;
  color: #330066;
}
.splash__grid--center .splash__box--icon {
  position: absolute;
  top: 16px;
  right: 37px;
  width: 80px;
  height: auto;
  z-index: 1;
}
.splash__box {
  background: #fff;
  text-align: center;
  position: relative;
  box-sizing: border-box;
  display: grid;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.16);
}
@media only screen and (min-width: 768px) {
  .splash__box {
    grid-template-rows: 60px 1fr 50px;
    padding: 0 30px 40px;
    height: 350px;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__box {
    grid-template-rows: 80px 1fr 50px;
    padding: 0 40px 60px;
    height: 410px;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__box:before {
    pointer-events: none;
    content: "";
    width: 640px;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s ease;
  }
}
.splash__box--subtitle, .splash__box--text {
  color: #362063;
  font-weight: 400;
  margin-top: 0;
}
.splash__box--title {
  display: flex;
  text-align: left;
  position: relative;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 20px 25px;
}
@media only screen and (min-width: 768px) {
  .splash__box--title {
    text-align: center;
    justify-content: center;
    padding: 0;
    margin: 0;
  }
}
.splash__box--title:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: -30px;
  width: calc(100% + 60px);
  height: 2px;
}
@media only screen and (min-width: 1200px) {
  .splash__box--title:after {
    left: -40px;
    width: calc(100% + 80px);
    transition: all 0.3s ease;
  }
}
.splash__box--subtitle {
  line-height: 1.3;
  margin-bottom: 25px;
  padding-left: 10vw;
  padding-right: 10vw;
}
.splash__box--subtitle {
  font-size: 20px;
}
@media screen and (min-width: 360px) {
  .splash__box--subtitle {
    font-size: calc(20px + 6 * ((100vw - 360px) / 840));
  }
}
@media screen and (min-width: 1200px) {
  .splash__box--subtitle {
    font-size: 26px;
  }
}
@media only screen and (min-width: 768px) {
  .splash__box--subtitle {
    margin: 20px 0;
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__box--subtitle {
    margin: 30px 0;
  }
}
.splash__box--text {
  line-height: 1.2;
  font-size: 18px;
  padding-left: 10vw;
  padding-right: 10vw;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .splash__box--text {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
}
.splash__box--logo {
  max-width: 100%;
  height: auto;
  width: 185px;
  margin-bottom: 29px;
}
@media only screen and (min-width: 768px) {
  .splash__box--logo {
    position: absolute;
    width: 100px;
    top: -35px;
    right: -10px;
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__box--logo {
    width: 140px;
    top: -44px;
    right: -23px;
  }
}
@media only screen and (min-width: 1400px) {
  .splash__box--logo {
    width: 179px;
    top: -58px;
    right: -55px;
  }
}
.splash__box--link {
  transition: all 0.3s ease;
  align-self: flex-end;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  color: #fff;
  font-weight: 400;
  line-height: 1;
  font-size: 16px;
  width: 215px;
  height: 38px;
  justify-self: center;
  margin: 0 auto;
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) {
  .splash__box--link {
    justify-self: unset;
    margin-bottom: 0;
    line-height: 24px;
    font-size: 18px;
    width: 247px;
    height: 50px;
  }
}
@media only screen and (min-width: 768px) {
  .splash__box--details {
    margin-bottom: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  .splash__box--details {
    margin-bottom: 50px;
  }
}
.splash__footer {
  width: 100%;
  height: 25px;
  background-image: linear-gradient(to right, #362063 50%, #3EB7BA 50%);
  position: relative;
}
.splash__footer:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  height: 100%;
  background: #FFCC00;
}
.splash__leftBg, .splash__rightBg {
  position: absolute;
  z-index: -1;
  height: auto;
}
.splash__leftBg {
  top: 54px;
  left: 0;
  width: 10vw;
  min-width: 100px;
}
.splash__rightBg {
  bottom: 90px;
  right: 0;
  width: 9vw;
  min-width: 90px;
}