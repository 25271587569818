//  Cloudt Splash Screen Scss
//  ------------------------

// Imports
// -------------------
@import url("https://use.typekit.net/nth3kzr.css");

// Responsive Font Size
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}
/* // Use Example
$min_width: 360px;
$max_width: 1920px;
$min_font: 18px;
$max_font: 55px;
@include fluid-type($min_width, $max_width, $min_font, $max_font); */

.show-for-medium {
    display: none;

    @media only screen and (min-width: 768px) {
        display: block;
    }
}

.hide-for-medium {
    @media only screen and (min-width: 768px) {
        display: none;
    }
}

.splash {
    overflow-x: hidden;
    position: relative;
    font-family: "co-headline",sans-serif;
    font-weight: 400;
    background: #F0F0F0;
    min-height: 100vh;
    display: grid;
    grid-template-rows: 0px auto 1fr 25px;
    margin: 0;
    padding: 0;

    &__header {
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
            width: 63vw;
            min-width: 228px;

            @media only screen and (min-width: 768px) {
                width: 40vw;
            }

            @media only screen and (min-width: 992px) {
                width: 22vw;
                min-width: 250px;
                max-width: 420px;
            }
        }
    }

    &__content {
        overflow-x: hidden;

        &--espa {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 80px;

            @media only screen and (min-width: 768px) {
                margin-bottom: 100px;
            }

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }

    &__grid {
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        margin-top: 28px;
        margin-bottom: 20px;

        @media only screen and (min-width: 768px) {
            grid-template-columns: 1fr 1fr;
            align-items: flex-end;
            column-gap: 40px;
            margin-top: 60px;
            margin-bottom: 100px;
        }

        @media only screen and (min-width: 1200px) {
            grid-template-columns: 1fr 1fr;
            column-gap: 65px;
        }

        @media only screen and (min-width: 1400px) {
            column-gap: 200px;
        }

        @media only screen and (min-width: 1500px) {
            column-gap: 290px;
        }

        @media only screen and (min-width: 1640px) {
            grid-template-columns: 1fr 1fr 1fr;
            // grid-template-columns: 1fr 1fr;
            column-gap: 50px;
        }

        &--title {
            color: #362063;
            font-weight: 400;
            line-height: 1.3;
            @include fluid-type(360px, 1920px, 16px, 18px);
            margin-top: 0;
            margin-bottom: 17px;
            text-align: center;
            
            @media only screen and (min-width: 768px) {
                margin-bottom: 14px;
                // text-align: left;
            }
        }

        &--left, &--right, &--center {
            margin-bottom: 60px;

            @media only screen and (min-width: 768px) {
                margin-bottom: 80px;
                width: 350px;
            }

            @media only screen and (min-width: 1200px) {
                // width: 526px;
                width: 500px;
            }

            @media only screen and (min-width: 1640px) {
                margin-bottom: 0;
                order: 1;
            }

            a {
                text-decoration: none;
                transition: all .3s ease;

                &:hover {
                    @media only screen and (min-width: 1200px) {
                        .splash__box {
                            &:before {
                                opacity: 1;
                            }

                            &--link {
                                box-shadow: 0px 3px 6px #00000029;
                            }
                        }
                    }
                }
            }
        }

        &--left {
            @media only screen and (min-width: 768px) {
                justify-self: flex-end;
            }

            @media only screen and (min-width: 1640px) {
                order: 1;
            }

            .splash__box {
                &:before {
                    @media only screen and (min-width: 1200px) {
                        height: 439px;
                        background-image: url('/img/splash/desktop/invoicing-hover.svg');
                        left: 54%;
                    }
                }

                &--title {
                    color: #362063;

                    // @media only screen and (min-width: 768px) {
                    //     margin-bottom: 42px;
                    // }

                    &:after {
                        background: #34A2A2;
                    }

                    svg {
                        height: auto;
                        width: 120px;

                        @media only screen and (min-width: 768px) {
                            width: 8.8vw;
                            min-width: 120px;
                            max-width: 167px;
                        }
                    }
                }

                &--link {
                    background: #34A2A2;
                }

                &--icon {
                    position: absolute;
                    top: 16px;
                    right: 37px;
                    width: 80px;
                    height: auto;
                    z-index: 1;
                }
            }
        }

        &--right {
            @media only screen and (min-width: 1640px) {
                order: 3;
            }

            .splash__box {
                &:before {
                    @media only screen and (min-width: 1200px) {
                        height: 431px;
                        background-image: url('/img/splash/desktop/accounting-hover.svg');
                        left: 46%;
                    }
                }

                &--title {
                    color: #3EB7BA;

                    // @media only screen and (min-width: 768px) {
                    //     margin-bottom: 20px;
                    // }

                    &:after {
                        background: #362063;
                    }

                    span {
                        color: #FFCC00;
                    }

                    img {
                        height: auto;
                        width: 140px;

                        @media only screen and (min-width: 768px) {
                            width: 10vw;
                            min-width: 130px;
                            max-width: 189px;
                        }
                    }
                }

                &--link {
                    background: #362063;
                }

                &--icon {
                    position: absolute;
                    top: 14px;
                    right: 37px;
                    width: 64px;
                    height: auto;
                    z-index: 1;
                }
            }
        }

        &--center {
            margin: 0 auto 60px;
            grid-column: 1/-1;

            @media only screen and (min-width: 768px) {
                margin: 0 auto 80px;
            }

            @media only screen and (min-width: 1640px) {
                margin: 0 auto;
                grid-column: span 1 / span 1;
                order: 2;
            }

            .splash__box {
                &:before {
                    @media only screen and (min-width: 1200px) {
                        height: 431px;
                        background-image: url('/img/ypahes/ypaies.svg');
                        left: 46%;
                    }
                }

                &--title {
                    color: #3EB7BA;

                    // @media only screen and (min-width: 768px) {
                    //     margin-bottom: 20px;
                    // }

                    &:after {
                        background: #FFCC00;
                    }

                    span {
                        color: #FFCC00;
                    }

                    svg {
                        height: auto;
                        width: 120px;

                        @media only screen and (min-width: 768px) {
                            width: 8.8vw;
                            min-width: 120px;
                            max-width: 167px;
                        }
                    }
                }

                &--link {
                    background: #FFCC00;
                    color: #330066;
                }

                &--icon {
                    position: absolute;
                    top: 16px;
                    right: 37px;
                    width: 80px;
                    height: auto;
                    z-index: 1;
                }
            }
        }
    }

    &__box {
        background: #fff;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        display: grid;
        box-shadow: 6px 6px 6px rgba($color: #000000, $alpha: 0.16);

        @media only screen and (min-width: 768px) {
            grid-template-rows: 60px 1fr 50px;
            padding: 0 30px 40px;
            height: 350px;
        }

        @media only screen and (min-width: 1200px) {
            grid-template-rows: 80px 1fr 50px;
            padding: 0 40px 60px;
            height: 410px;
        }

        &:before {
            @media only screen and (min-width: 1200px) {
                pointer-events: none;
                content: "";
                width: 640px;
                background-repeat: no-repeat;
                background-position: center top;
                background-size: cover;
                position: absolute;
                top: 0;
                transform: translateX(-50%);
                opacity: 0;
                transition: all .3s ease;
            }
        }

        &--subtitle, &--text {
            color: #362063;
            font-weight: 400;
            margin-top: 0;
        }

        &--title {
            display: flex;
            text-align: left;
            position: relative;
            margin-top: 0;
            margin-bottom: 30px;
            padding: 20px 25px;

            @media only screen and (min-width: 768px) {
                text-align: center;
                justify-content: center;
                padding: 0;
                margin: 0;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: -30px;
                width: calc(100% + 60px);
                height: 2px;

                @media only screen and (min-width: 1200px) {
                    left: -40px;
                    width: calc(100% + 80px);
                    transition: all .3s ease;
                }
            }
        }

        &--subtitle {
            line-height: 1.3;
            @include fluid-type(360px, 1200px, 20px, 26px);
            margin-bottom: 25px;
            padding-left: 10vw;
            padding-right: 10vw;

            @media only screen and (min-width: 768px) {
                margin: 20px 0;
                padding-left: 0;
                padding-right: 0;
            }

            @media only screen and (min-width: 1200px) {
                margin: 30px 0;
            }
        }

        &--text {
            line-height: 1.2;
            font-size: 18px;
            padding-left: 10vw;
            padding-right: 10vw;
            margin-bottom: 30px;

            @media only screen and (min-width: 768px) {
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;
            }
        }

        &--logo {
            max-width: 100%;
            height: auto;
            width: 185px;
            margin-bottom: 29px;
            
            @media only screen and (min-width: 768px) {
                position: absolute;
                width: 100px;
                top: -35px;
                right: -10px;
                margin-bottom: 0;
            }

            @media only screen and (min-width: 1200px) {
                width: 140px;
                top: -44px;
                right: -23px;
            }

            @media only screen and (min-width: 1400px) {
                width: 179px;
                top: -58px;
                right: -55px;
            }
        }

        &--link {
            transition: all .3s ease;
            align-self: flex-end;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 27px;
            color: #fff;
            font-weight: 400;
            line-height: 1;
            font-size: 16px;
            width: 215px;
            height: 38px;
            justify-self: center;
            margin: 0 auto;
            margin-bottom: 50px;

            @media only screen and (min-width: 768px) {
                justify-self: unset;
                margin-bottom: 0;
                line-height: 24px;
                font-size: 18px;
                width: 247px;
                height: 50px;
            }
        }

        &--details {
            @media only screen and (min-width: 768px) {
                margin-bottom: 40px;
            }

            @media only screen and (min-width: 1200px) {
                margin-bottom: 50px;
            }
        }
    }

    &__footer {
        width: 100%;
        height: 25px;
        background-image: linear-gradient(to right, #362063 50%, #3EB7BA 50%);
        position: relative;
        
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 3px;
            height: 100%;
            background: #FFCC00;
        }
    }

    &__leftBg, &__rightBg {
        position: absolute;
        z-index: -1;
        height: auto;
    }

    &__leftBg {
        top: 54px;
        left: 0;
        width: 10vw;
        min-width: 100px;
    }

    &__rightBg {
        bottom: 90px;
        right: 0;
        width: 9vw;
        min-width: 90px;
    }
}